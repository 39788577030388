import React, { useState } from "react";
import { TbListDetails } from "react-icons/tb";
import { HashLink as Link } from "react-router-hash-link";
import images from "../../constants/images";
import "./HomeProducts.css";

const HomeProducts = () => {
  const [seeMore, setSeeMore] = useState(false);
  let review = {
    Oilseeds:
      "Tatva Overseas, exporters of oil seeds from India, specializes in delivering premium-quality agricultural products to global markets. Our diverse product range includes Raw Peanuts, Peanuts in Shell, Blanched Peanuts, Natural Sesame Seeds, Black Sesame Seeds, Hulled Sesame Seeds, and Roasted Sesame Seeds. With a commitment to excellence and customer satisfaction, we ensure meticulous sourcing, stringent quality checks, and efficient logistics to meet international standards. At Tatva Overseas, we take pride in fostering trust and long-lasting partnerships by providing the finest oil seeds to fulfill the needs of our esteemed clients worldwide.",
    FruitsVegetables:
      "We specializes in exporting high-quality, farm-fresh fruits and vegetables to global markets. We work directly with trusted local farmers to ensure that each product meets rigorous standards of freshness, hygiene, and quality. Through efficient logistics and careful handling, we deliver produce that retains its natural taste and nutritional value, bringing the best harvest to your business.",
    Spices:
      "We offers a premium selection of spices, carefully sourced from renowned regions to bring authentic flavor and quality to global markets. Our spices are meticulously processed to retain their rich aroma and natural potency, ensuring every shipment meets the highest standards. With a commitment to quality and freshness, we deliver the essence of traditional spice culture right to your business.",
    Grocery_items:
      "We provide a diverse range of high-quality grocery products, sourced with care to meet the needs of international markets. From staple grains and legumes to specialty items, our products are selected for their freshness, quality, and adherence to strict standards. We are dedicated to reliable and timely delivery, ensuring that every shipment brings essential, trusted groceries to your business.",
  };

  return (
    <div style={{ background: "#e8f4d9" }}>
      <div className="values_head_main" style={{ paddingTop: "1rem" }}>
        <div className="heading">
          <h1>OUR PRODUCTS</h1>
        </div>
        <div className="underline"></div>
      </div>
      <div className="main_flex" style={{ paddingTop: "0px" }}>
        <div className="wrapper">
          <div className="container">
            <div
              className="top"
              style={{
                backgroundImage: `url(${images.Oilseeds})`,
                backgroundPosition: "center",
                backdropFilter: "blur(2px)",
                WebkitBackdropFilter: "revert-layer",
                backgroundSize: "cover",
              }}></div>
            <div className="bottom">
              <Link to="/OilSeeds" className="left">
                <div className="details">
                  <h4>Oil Seeds</h4>
                </div>
              </Link>
            </div>
          </div>
          <div className="inside">
            <TbListDetails className="icon" size={24} />
            <div className="contents">
              <p className="pro_card_text">
                {review.Oilseeds === "" ||
                review.Oilseeds == null ||
                review.Oilseeds === undefined ? null : (
                  <>
                    {seeMore
                      ? review.Oilseeds
                      : `${review.Oilseeds.substring(0, 290)}...`}
                    <Link
                      to="/OilSeeds"
                      className="btn-readmore"
                      style={{
                        background: "none",
                        color: "#000",
                        border: "none",
                        cursor: "pointer",
                        padding: "0px",
                        fontWeight: "600",
                      }}
                      aria-label={
                        seeMore
                          ? "Show less content about Oil Seeds"
                          : "Read more about Oil Seeds"
                      }
                      onClick={() => setSeeMore(!seeMore)}>
                      {seeMore ? "Show less" : "  Read more"}
                    </Link>
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="container">
            <div
              className="top"
              style={{
                backgroundImage: `url(${images.fruitsVeges})`,
                backgroundPosition: "center",
                backdropFilter: "blur(2px)",
                WebkitBackdropFilter: "revert-layer",
                backgroundSize: "cover",
              }}></div>
            <div className="bottom">
              <Link to="/FruitsVeges" className="left">
                <div className="details">
                  <h4>Fruits & Vegetables</h4>
                </div>
              </Link>
            </div>
          </div>
          <div className="inside">
            <TbListDetails className="icon" size={24} />
            <div className="contents">
              <p className="pro_card_text">
                {review.FruitsVegetables === "" ||
                review.FruitsVegetables == null ||
                review.FruitsVegetables === undefined ? null : (
                  <>
                    {seeMore
                      ? review.FruitsVegetables
                      : `${review.FruitsVegetables.substring(0, 300)}...`}
                    <Link
                      to="/FruitsVeges"
                      className="btn-readmore"
                      style={{
                        background: "none",
                        color: "#000",
                        border: "none",
                        cursor: "pointer",
                        padding: "0px",
                        fontWeight: "600",
                      }}
                      aria-label={
                        seeMore
                          ? "Show less content about Fruits and Vegetables"
                          : "Read more about Fruits and Vegetables"
                      }
                      onClick={() => setSeeMore(!seeMore)}>
                      {seeMore ? "Show less" : "  Read more"}
                    </Link>
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="container">
            <div
              className="top"
              style={{
                backgroundImage: `url(${images.spices})`,
                backgroundPosition: "center",
                backdropFilter: "blur(2px)",
                WebkitBackdropFilter: "revert-layer",
                backgroundSize: "cover",
              }}></div>
            <div className="bottom">
              <Link to="/Spices" className="left">
                <div className="details">
                  <h4>Spices Products</h4>
                </div>
              </Link>
            </div>
          </div>
          <div className="inside">
            <TbListDetails className="icon" size={24} />
            <div className="contents">
              <p className="pro_card_text">
                {review.Spices === "" ||
                review.Spices == null ||
                review.Spices === undefined ? null : (
                  <>
                    {seeMore
                      ? review.Spices
                      : `${review.Spices.substring(0, 295)}...`}
                    <Link
                      to="/Spices"
                      className="btn-readmore"
                      style={{
                        background: "none",
                        color: "#000",
                        border: "none",
                        cursor: "pointer",
                        padding: "0px",
                        fontWeight: "600",
                      }}
                      aria-label={
                        seeMore
                          ? "Show less content about Spices"
                          : "Read more about Spices"
                      }
                      onClick={() => setSeeMore(!seeMore)}>
                      {seeMore ? "Show less" : "  Read more"}
                    </Link>
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="container">
            <div
              className="top"
              style={{
                backgroundImage: `url(${images.Grocery})`,
                backgroundPosition: "center",
                backdropFilter: "blur(2px)",
                WebkitBackdropFilter: "revert-layer",
                backgroundSize: "cover",
              }}></div>
            <div className="bottom">
              <Link to="/Groceries" className="left">
                <div className="details">
                  <h4>Grocery Products</h4>
                </div>
              </Link>
            </div>
          </div>
          <div className="inside">
            <TbListDetails className="icon" size={24} />
            <div className="contents">
              <p className="pro_card_text">
                {review.Grocery_items === "" ||
                review.Grocery_items == null ||
                review.Grocery_items === undefined ? null : (
                  <>
                    {seeMore
                      ? review.Grocery_items
                      : `${review.Grocery_items.substring(0, 296)}...`}
                    <Link
                      to="/Groceries"
                      className="btn-readmore"
                      style={{
                        color: "#000",
                        border: "none",
                        cursor: "pointer",
                        padding: "0px",
                        fontWeight: "600",
                      }}
                      aria-label={
                        seeMore
                          ? "Show less content about Groceries"
                          : "Read more about Groceries"
                      }
                      onClick={() => setSeeMore(!seeMore)}>
                      {seeMore ? "Show less" : "  Read more"}
                    </Link>
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeProducts;
