import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../common/navbar/Navbar";
import images from "../../constants/images";
import "./Spices.css";

const Spices = () => {
  const photos = [
    { id: 1, name: "Clove", url: images.clove },
    { id: 2, name: "Cinnamon", url: images.Cinnamon },
    { id: 3, name: "Cardamom", url: images.cardamom },
    {
      id: 4,
      name: "Black Pepper Powder",
      url: images.blackpepper,
    },
    { id: 5, name: "Star anise", url: images.staranise },
    { id: 6, name: "Turmeric / Turmeric Powder", url: images.Turmeric },
    { id: 7, name: "Red chilli / chilli Powder", url: images.RedchilliPowder },
    { id: 8, name: "Dry Ginger Powder", url: images.DryGingerPowder },
    { id: 9, name: "cumin Seed", url: images.cuminseed },
    { id: 10, name: "Coriander Seeds", url: images.CorianderSeeds },
    { id: 11, name: "Fennel Seeds", url: images.FennelSeeds },
    { id: 12, name: "Fenugreek Seeds", url: images.FenugreekSeeds },
    { id: 13, name: "Mustard Seeds", url: images.MustardSeeds },
    { id: 14, name: "Yellow Mustard Seeds", url: images.Yellowmustard },
    // { id: 15, name: "Sesame Seed", url: images.SesameSeed },
    { id: 16, name: "Ajwain", url: images.Ajwain },
    // { id: 17, name: "Dill Seeds", url: images.DillSeeds },
  ];

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPhotos, setFilteredPhotos] = useState(photos);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = photos.filter((photo) =>
      photo.name.toLowerCase().includes(query)
    );
    setFilteredPhotos(filtered);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tatva Overseas | Spices</title>
        <meta
          name="description"
          content="Exporting the finest Indian spices worldwide. From turmeric to black pepper, our spices deliver unmatched aroma, flavor, and quality."
        />
      </Helmet>
      <div style={{ paddingBottom: "0rem" }}>
        <Navbar />
      </div>
      <div className="pro_head_main">Spices</div>

      <div className="pro_main_para">
        <p className="para">
          At Tatva Overseas, we specialize in the export of a diverse range of
          high-quality spices that cater to the evolving needs of global
          markets. Our premium selection includes aromatic and flavorful
          products like <strong>Clove</strong>, <strong>Cinnamon</strong>,{" "}
          <strong>Cardamom</strong>, <strong>Black Pepper Powder</strong>, and{" "}
          <strong>Star Anise</strong>, each sourced from trusted growers to
          ensure freshness and authenticity. We also offer essential kitchen
          staples such as
          <strong>Turmeric</strong>, <strong>Red Chilli Powder</strong>,{" "}
          <strong>Dry Ginger Powder</strong>, and <strong>Cumin Seeds</strong>,
          which are cultivated with a commitment to sustainable practices. Our
          spices are carefully processed to preserve their natural aroma, color,
          and taste, providing our customers with ingredients that enhance the
          culinary experience.{" "}
        </p>
        <p className="para" style={{marginTop:"0px"}}>
          We understand the growing demand for pure and organic spices, and as
          part of our commitment to quality, we ensure that each product is
          rigorously tested and packaged under the best standards. Whether it's
          the warmth of <strong>Coriander Seeds</strong>, the zest of{" "}
          <strong>Fennel Seeds</strong>, or the versatility of{" "}
          <strong>Fenugreek</strong> and <strong>Mustard Seeds</strong>, our
          spices are chosen for their authenticity and flavor. Tatva Overseas
          prides itself on building strong relationships with farmers and
          suppliers, supporting sustainable agriculture, and bringing the best
          of Indian spices to global markets. With a focus on customer
          satisfaction, we offer timely deliveries and competitive pricing,
          ensuring that every order meets the highest standards of excellence.
        </p>
      </div>

      <div className="searchcontainer">
        <input
          type="text"
          placeholder="Search Spices..."
          value={searchQuery}
          onChange={handleSearch}
          className="searchinput"
        />
      </div>
      <div className="FruitsVeges_main">
        <div className="Fruits_main">
          {filteredPhotos.map((photo) => (
            <figure key={photo.id} className="gallery_item">
              <img
                src={photo.url}
                alt={photo.name}
                className="Fruits_main_img"
              />
              <figcaption className="hover_text">{photo.name}</figcaption>
            </figure>
          ))}
          {filteredPhotos.length === 0 && (
            <p style={{ textAlign: "center", marginTop: "20px" }}>
              No results found for "{searchQuery}".
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default Spices;
