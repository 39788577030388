import React from "react";
import { AiOutlineMail } from "react-icons/ai";
import { BsChatText } from "react-icons/bs";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import images from "../../constants/images";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <div className="footer_main1">
        <div className="footer_main">
          <div className="footer_box1">
            <div className="headings" style={{ marginBottom: "1rem" }}>
              <h3>ABOUT US</h3>
            </div>
            <div className="aboutuspara">
              <p>
                Tatva Overseas specializes in the sourcing and exporting of
                high-quality fruits, vegetables, spices, and grocery products
                from India to international markets. We are committed to
                maintaining high standards of freshness and safety, ensuring our
                clients receive the finest produce from reliable sources.
              </p>
            </div>
          </div>
          <div className="footer_box3">
            <div className="headings">
              <h3>USEFUL LINKS</h3>
            </div>
            <div className="links_sec2">
              <Link to="/" className="link_inner">
                Home
              </Link>
              <Link to="/About" className="link_inner">
                About us
              </Link>
              <Link to="/Contact" className="link_inner">
                Contact Us
              </Link>
            </div>
          </div>
          <div className="footer_box3">
            <div className="headings">
              <h3>PRODUCTS</h3>
            </div>
            <div className="links_sec2">
              <Link to="/Oilseeds" className="link_inner">
                Oil Seeds
              </Link>
              <Link to="/FruitsVeges" className="link_inner">
                Fruits & Vegetables
              </Link>
              <Link to="/Spices" className="link_inner">
                Spices
              </Link>
              <Link to="/Groceries" className="link_inner">
                Grocery items
              </Link>
            </div>
          </div>

          <div className="footer_link_flex">
            <div className="headings">
              <h3>REGI. OFFICE ADDRESS</h3>
            </div>
            <p className="footer_add_main">
              54,Shiv Aradhna soc., B/H Alap Heritage, Satya sai road, Rajkot,
              Gujarat, India
            </p>
            <div className="footer_icon_flex">
              <BsChatText
                style={{
                  color: "white",
                  alignSelf: "center",
                  height: "22px",
                  width: "22px",
                }}
                className="con-info-icon"
              />
              <Link to="tel:+91 9725040440" className="footer_link">
                +91 97250 40440
              </Link>
            </div>{" "}
            <div className="footer_icon_flex">
              <AiOutlineMail className="link_footer_icon" />

              <Link
                to="mailto:info@tatvaoverseas.in"
                className="footer_add_main">
                info@tatvaoverseas.in
              </Link>
            </div>
            <div className="footer_icon_flex">
              <AiOutlineMail className="link_footer_icon" />

              <Link
                to="mailto:tatvaoverseas@gmail.com"
                className="footer_add_main">
                tatvaoverseas@gmail.com
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="last_sec" style={{ borderTop: "1px solid white" }}>
        <div className="rights_reserved">
          <p>© 2024 All Rights Reserved</p>
        </div>

        <div className="Footer_main_social">
          <Link to="https://www.facebook.com">
            <FaFacebookF className="Footer_main_facebook_social" />
          </Link>
          <Link to="https://twitter.com">
            <FaTwitter className="Footer_main_twitter_social" />
          </Link>
          <Link to="https://www.instagram.com/tatvaoverseas/">
            <FaInstagram className="Footer_main_insta_social" />
          </Link>
          <Link to="https://in.pinterest.com/">
            <FaPinterestP className="Footer_main_pinterest_social" />
          </Link>

          <Link to="http://api.whatsapp.com/send?phone=&text=Let%27s%20Get%20in%20Touch">
            <FaWhatsapp className="Footer_main_whatsapp_social" />
          </Link>
        </div>
      </div> */}
      <div className="flinkbtn">
        <Link
          to="https://wa.link/ankt1u"
          to="https://wa.link/0oj5ge"
          target="_blank"
          aria-label="Contact us on WhatsApp using the number"
          className="whatsapp-link">
          <FaWhatsapp />
          <div className="qrcode-container">
            <img
              src={images.Whatsapp_QR_Code}
              alt="WhatsApp QR Code to chat with us"
              className="qrcode-image"
            />
          </div>
        </Link>
      </div>
    </>
  );
};

export default Footer;
