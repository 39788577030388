import Customer_icon from "../assets/Customer_icon.png";
import GlobalReach from "../assets/GlobalReach.png";
import Grocery from "../assets/Grocery.webp";
import Integrity_icon from "../assets/Integrity_icon.png";
import Logistics from "../assets/Logistics.png";
import Relationship from "../assets/Relationship.png";
import Sustainability_icon from "../assets/Sustainability_icon.webp";
import TrustedPartnership from "../assets/TrustedPartnership.png";
import bgImage from "../assets/bgImage.jpg";
import community from "../assets/community.png";
import customer_centric from "../assets/customer_centric.webp";
import fruitsVeges from "../assets/fruitsVeges.webp";
import mission_icon from "../assets/mission_icon.png";
import quality from "../assets/quality.webp";
import qualityAssurance from "../assets/qualityAssurance.png";
import shipment from "../assets/shipment.jpg";
import spices from "../assets/spices.webp";
import spices_hero from "../assets/spices_hero.webp";
import transparency from "../assets/transparency.png";
import vision_icon from "../assets/vision_icon.png";
import whyUs from "../assets/whyUs.jpg";

import Ajwain from "../assets/Ajwain.png";
import Bajra from "../assets/Bajra.webp";
import BlackSesameSeed from "../assets/BlackSesameSeed.jpg";
import BlanchPeanuts from "../assets/BlanchPeanuts.webp";
import BoldPeanut from "../assets/BoldPeanut.webp";
import Chanadal from "../assets/Chanadal.png";
import Chickpea from "../assets/Chickpea.jpg";
import Cinnamon from "../assets/Cinnamon.jpg";
import CorianderSeeds from "../assets/CorianderSeeds.jpg";
import DillSeeds from "../assets/DillSeeds.jpg";
import Drumstick from "../assets/Drumstick.jpg";
import DryGingerPowder from "../assets/DryGingerPowder.webp";
import ElephantYam from "../assets/ElephantYam.jpg";
import FennelSeeds from "../assets/FennelSeeds.jpg";
import FenugreekSeeds from "../assets/FenugreekSeeds.webp";
import Ginger from "../assets/Ginger.jpg";
import Grapes from "../assets/Grapes.jpg";
import HulledSesameSeeds from "../assets/HulledSesameSeeds.jpg";
import HuskedCoconut from "../assets/HuskedCoconut.jpg";
import JavaPeanut from "../assets/JavaPeanut.jpg";
import Lemon from "../assets/Lemon.webp";
import MasoorDal from "../assets/MasoorDal.jpg";
import MothDal from "../assets/MothDal.webp";
import MuskMelon from "../assets/MuskMelon.jpg";
import MustardSeeds from "../assets/MustardSeeds.jpg";
import NaturalSesameSeeds from "../assets/NaturalSesameSeeds.webp";
import Oilseeds from "../assets/Oilseeds.webp";
import Okra from "../assets/Okra.jpg";
import Onion from "../assets/Onion.jpeg";
import PeanutInShell from "../assets/PeanutInShell.webp";
import Peas from "../assets/Peas.jpg";
import Potato from "../assets/Potato.jpg";
import Ragi from "../assets/Ragi.webp";
import Rajma from "../assets/Rajma.webp";
import RawPeanut from "../assets/RawPeanut.jpg";
import RedchilliPowder from "../assets/RedchilliPowder.webp";
import RoastedSesameSeed from "../assets/RoastedSesameSeed.png";
import Sapodilla from "../assets/Sapodilla.jpg";
import SesameSeed from "../assets/SesameSeed.webp";
import Sorghum from "../assets/Sorghum.jpg";
import TJPeanut from "../assets/TJPeanut.jpg";
import Tomato from "../assets/Tomato.jpg";
import ToorDal from "../assets/ToorDal.jpg";
import Turmeric from "../assets/Turmeric.jpg";
import UradDal from "../assets/UradDal.jpg";
import Whatsapp_QR_Code from "../assets/Whatsapp_QR_Code.png";
import Yellowmustard from "../assets/Yellowmustard.webp";
import banana from "../assets/banana.png";
import blackpepper from "../assets/blackpepper.webp";
import cardamom from "../assets/cardamom.jpg";
import clove from "../assets/clove.jpg";
import cuminseed from "../assets/cuminseed.webp";
import dryPeas from "../assets/dryPeas.webp";
import mango from "../assets/mango.jpg";
import pomegranate from "../assets/pomegranate.jpg";
import staranise from "../assets/staranise.webp";
import Groundnut from "../assets/Groundnut.jpg";

export default {
  bgImage,
  shipment,
  spices_hero,
  qualityAssurance,
  Sustainability_icon,
  transparency,
  community,
  Customer_icon,
  Integrity_icon,
  fruitsVeges,
  spices,
  Grocery,
  whyUs,
  quality,
  TrustedPartnership,
  customer_centric,
  Logistics,
  GlobalReach,
  Relationship,
  mission_icon,
  vision_icon,
  mango,
  banana,
  pomegranate,
  Sapodilla,
  Grapes,
  HuskedCoconut,
  Okra,
  Drumstick,
  Lemon,
  MuskMelon,
  Onion,
  Potato,
  Ginger,
  Tomato,
  ElephantYam,
  Peas,
  dryPeas,
  UradDal,
  ToorDal,
  MasoorDal,
  Chickpea,
  Chanadal,
  MothDal,
  Rajma,
  clove,
  Cinnamon,
  cardamom,
  blackpepper,
  staranise,
  Turmeric,
  RedchilliPowder,
  DryGingerPowder,
  cuminseed,
  CorianderSeeds,
  FennelSeeds,
  FenugreekSeeds,
  MustardSeeds,
  Yellowmustard,
  Ajwain,
  SesameSeed,
  DillSeeds,
  Whatsapp_QR_Code,
  RawPeanut,
  PeanutInShell,
  NaturalSesameSeeds,
  BlackSesameSeed,
  HulledSesameSeeds,
  RoastedSesameSeed,
  Bajra,
  Sorghum,
  Ragi,
  BoldPeanut,
  JavaPeanut,
  TJPeanut,
  BlanchPeanuts,
  Oilseeds,
  Groundnut,
};
