import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../common/navbar/Navbar";
import images from "../../constants/images";
import "./Groceries.css";

const Groceries = () => {
  const milletjson = [
    { id: 1, name: "Bajra", url: images.Bajra },
    { id: 2, name: "Sorghum", url: images.Sorghum },
    { id: 2, name: "Ragi", url: images.Ragi },
  ];
  const photos = [
    { id: 1, name: "Toor Dal (Pigeon Pea split)", url: images.ToorDal },
    { id: 2, name: "Urad Dal (Black Gram split)", url: images.UradDal },
    { id: 3, name: "Masoor Dal (Red Lentil )", url: images.MasoorDal },
    {
      id: 4,
      name: "Chickpea (Garbanzo / Chhola-Chhana)",
      url: images.Chickpea,
    },
    { id: 5, name: "Split chickpeas (Chana Dal)", url: images.Chanadal },
    { id: 6, name: "Moth Dal", url: images.MothDal },
    { id: 7, name: "Kidney Beans (Rajma)", url: images.Rajma },
    { id: 8, name: "Peas", url: images.dryPeas },
  ];

  const [searchQuery0, setSearchQuery0] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredMillets, setFilteredMillets] = useState(milletjson);
  const [filteredPhotos, setFilteredPhotos] = useState(photos);

  const handleSearchMillet = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery0(query);

    const filtered = milletjson.filter((photo) =>
      photo.name.toLowerCase().includes(query)
    );
    setFilteredMillets(filtered);
  };
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = photos.filter((photo) =>
      photo.name.toLowerCase().includes(query)
    );
    setFilteredPhotos(filtered);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tatva Overseas | Groceries</title>
        <meta
          name="description"
          content="Offering a wide range of premium Indian millets & pulses. From protein-packed lentils to rich chickpeas, our pulses guarantee superior quality, taste, and nutrition."
        />
      </Helmet>
      <div style={{ paddingBottom: "0rem" }}>
        <Navbar />
      </div>

      <div className="pro_head_main">Groceries</div>
      <div className="pro_main_para">
        <p className="para">
          At Tatva Overseas, we specialize in exporting high-quality grocery
          products to meet global demands. Our range includes pulses like
          <strong>Toor Dal (Pigeon Pea)</strong>,{" "}
          <strong>Urad Dal (Black Gram)</strong>, and{" "}
          <strong>Masoor Dal (Red Lentil)</strong>,
          <strong>Chickpea (Garbanzo)</strong>,{" "}
          <strong>Chana Dal (Split Chickpeas)</strong>, and{" "}
          <strong>Moth Dal</strong>, all sourced for superior quality and
          nutrition. We also offer beans like{" "}
          <strong>Rajma (Kidney Beans)</strong> and nutrient-rich millets such
          as{" "}
          <strong>
            Bajra (Pearl Millet) and Sorghum (Jowar)and Ragi (Finger Millet )
          </strong>{" "}
          perfect for healthy and sustainable diets.
        </p>
        <p className="para" style={{marginTop:"0px"}}>
          Committed to quality, we work with trusted farmers to ensure
          sustainable practices and consistent, fresh products that meet
          international standards. With Tatva Overseas, you can rely on timely
          delivery of premium pulses, beans, and millets to suit your needs.
        </p>
      </div>
      <div>
        <>
          <div className="heading">
            <meta
              name="keywords"
              content="Indian Millets Suppliers, Indian Bajra, India Sorghum, Indian Finger Millet (Ragi)"
            />
            <h1>Varieties of Millets</h1>
          </div>
          <div className="underline" style={{ marginBottom: "0px" }}></div>
        </>{" "}
        <div className="searchcontainer">
          <input
            type="text"
            placeholder="Search varieties of Millets..."
            value={searchQuery0}
            onChange={handleSearchMillet}
            className="searchinput"
          />
        </div>
        <div className="FruitsVeges_main">
          <div className="Fruits_main">
            {filteredMillets.map((milletjson) => (
              <figure
                key={milletjson.id}
                className="gallery_item groceries_circular">
                <img
                  src={milletjson.url}
                  alt={milletjson.name}
                  className="Fruits_main_img"
                />
                <figcaption className="hover_text hover_text_grocery">
                  {milletjson.name}
                </figcaption>
              </figure>
            ))}
            {filteredMillets.length === 0 && (
              <p style={{ textAlign: "center", marginTop: "20px" }}>
                No results found for "{searchQuery0}".
              </p>
            )}
          </div>
        </div>
      </div>
      <div style={{ paddingTop: "2rem" }}>
        <>
          <div className="heading">
            <meta
              name="keywords"
              content="Indian Pulses, Pulses Exporters, Lentils, Chickpeas, Best Pulses Exporters"
            />
            <h1>Varieties of Pulses</h1>
          </div>
          <div className="underline" style={{ marginBottom: "0px" }}></div>
        </>
        <div className="searchcontainer">
          <input
            type="text"
            placeholder="Search varieties of Pulses..."
            value={searchQuery}
            onChange={handleSearch}
            className="searchinput"
          />
        </div>
        <div className="FruitsVeges_main">
          <div className="Fruits_main">
            {filteredPhotos.map((photo) => (
              <figure
                key={photo.id}
                className="gallery_item groceries_circular">
                <img
                  src={photo.url}
                  alt={photo.name}
                  className="Fruits_main_img"
                />
                <figcaption className="hover_text hover_text_grocery">
                  {photo.name}
                </figcaption>
              </figure>
            ))}
            {filteredPhotos.length === 0 && (
              <p style={{ textAlign: "center", marginTop: "20px" }}>
                No results found for "{searchQuery}".
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Groceries;

