import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [showProductsDropdown, setShowProductsDropdown] = useState(false);

  return (
    <div className="nav-main-nav-top">
      <nav className="Navbar_main">
        <div style={{ padding: "0.5rem 0.2rem", border: "1px solid white" }}>
          <Link
            to="/"
            style={{
              fontSize: "2rem",
              color: "white",
              fontFamily: "Merriweather Sans",
              border: "1px solid",
              padding: "0.3rem 0.5rem",
            }}>
            {/* <Link to="/" style={{ height: "90px", padding: "0.4rem 0" }}> */}
            Tatva Overseas
            {/* <img
              src={images.logo}
              alt=""
              style={{ height: "100%", width: "100%" }}
            /> */}
          </Link>
        </div>
        <div className="navbar_links_main">
          <div className="">
            <Link
              to="/"
              className="link_decor_nav nav_link_h3"
              style={
                {
                  // color:
                  //   location.pathname === "/"
                  //     ? "var(--color-white)"
                  //     : "var(--color-green-nav)",
                }
              }>
              Home
            </Link>
          </div>
          <div className="">
            <Link to="/About" className="link_decor_nav nav_link_h3">
              About
            </Link>
          </div>
          <div
            className="dropdown"
            onMouseEnter={() => setShowProductsDropdown(true)}
            onMouseLeave={() => setShowProductsDropdown(false)}>
            <Link className="link_decor_nav nav_link_h3">Products</Link>
            {showProductsDropdown && (
              <div className="dropdown-content">
                <Link to="/OilSeeds">Oil Seeds</Link> <hr />
                <Link to="/FruitsVeges">Fruits & Vegetables</Link>
                <hr />
                <Link to="/Spices">Spices</Link> <hr />
                <Link to="/Groceries">Groceries</Link>
              </div>
            )}
          </div>
          <div className="">
            <Link to="/Contact" className="link_decor_nav nav_link_h3">
              Contact
            </Link>
          </div>
        </div>
        <div className="nav-btn-icon-part">
          {toggleMenu === false ? (
            <button className="nav-btn" onClick={() => setToggleMenu(true)}>
              <AiOutlineMenu className="nav-btn-icon" />
            </button>
          ) : (
            <button className="nav-btn" onClick={() => setToggleMenu(false)}>
              <AiOutlineClose className="nav-btn-icon" />
            </button>
          )}
        </div>
      </nav>
      <>
        {toggleMenu && (
          <div className="mobile-nav-main">
            <ul className="mobile-nav-links">
              <li>
                <Link
                  to={"/"}
                  onClick={() => setToggleMenu(false)}
                  className="mobile-nav-link">
                  Home
                </Link>
              </li>
              <div className="dropdown">
                <Link to="/About" className="mobile-nav-link">
                  About
                  <i className="fa fa-caret-down"></i>
                </Link>
              </div>
              <div
                className="dropdown"
                onMouseEnter={() => setShowProductsDropdown(true)}
                onMouseLeave={() => setShowProductsDropdown(false)}>
                <Link className="link_decor_nav nav_link_h3">Products</Link>
                {showProductsDropdown && (
                  <div className="dropdown-content">
                    <Link to="/OilSeeds">Oil Seeds</Link> <hr />
                    <Link to="/FruitsVeges">Fruits & Vegetables</Link>
                    <hr />
                    <Link to="/Spices">Spices</Link> <hr />
                    <Link to="/Groceries">Groceries</Link>
                  </div>
                )}
              </div>

              <li>
                <Link
                  to={"/Contact"}
                  // onClick={() => setToggleMenu(false)}
                  className="mobile-nav-link">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        )}
      </>
    </div>
  );
};

export default Navbar;
